.Faq {
  display: grid;
  grid-template-areas: 'heading' 'faq' 'form';
  column-gap: clamp(84px, calc(92vw / 7.68), 134px);
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1.2fr;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    grid-template-areas: 'heading faq' 'form faq';
  }
  h2 {
    grid-area: heading;
    margin-bottom: clamp(24px, calc(36vw / 7.68), 64px);
  }
  .Form {
    grid-area: form;
    @media (max-width: 899px) {
      > .paragraph {
        margin-top: 64px;
      }
    }
  }
  .faq {
    grid-area: faq;
  }
}

.Form {
  max-width: calc(483rem / 16);
  form {
    margin-top: clamp(20px, calc(24vw / 7.68), 32px);
    display: grid;
    gap: clamp(20px, calc(24vw / 7.68), 32px);
  }
  position: relative;
  &:has(.State) {
    > p {
      visibility: hidden;
    }
    form > *:not(.State) {
      visibility: hidden;
    }
  }
}

.State {
  position: absolute;
  left: -16px;
  top: -16px;
  right: -16px;
  bottom: -16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--primary-900);
  h3 {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 8px;
  }
  p {
    margin: 16px 0 32px;
    font-size: var(--small-font-size);
  }
}

.faq {
  display: grid;
  gap: clamp(16px, calc(24vw / 7.68), 24px);
  details {
    position: relative;
    &::before {
      content: '';
      width: 2px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--secondary-500);
      transform: scaleX(0);
    }
    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
    border: 1px solid var(--primary-700);
    background: var(--gradient-bg);
    summary {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: clamp(20px, calc(32vw / 7.68), 32px);
      align-items: center;
      padding: clamp(16px, calc(20vw / 7.68), 20px);
      > svg {
        transform: translate3d(0, 0, 0);
        filter: drop-shadow(0px 0px 9px #98d6ff);
      }
    }
    .answer {
      padding: 0 clamp(16px, calc(20vw / 7.68), 20px);
      overflow: hidden;
      & > *:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &[data-opened='false'] {
      .answer {
        visibility: hidden;
        transition: visibility 0.5s;
      }
    }
    &[data-opened='true'] {
      &::before {
        transform: scaleX(1);
      }
      summary > svg {
        transform: rotateX(180deg);
      }
    }
  }
}
