.BadgeIconSection {
  border: 1px solid var(--secondary-500);
  background: var(--gradient-bg);
  padding: clamp(32px, calc(48vw / 7.68), 48px);
  display: grid;
  gap: clamp(24px, calc(32vw / 7.68), 32px);
  @media (min-width: 900px) {
    grid-template-columns: auto auto;
    grid-template-rows: auto 1fr;
    justify-content: space-between;
    h2 {
      grid-row: 2;
      align-self: flex-end;
    }
    .paragraph {
      grid-row: 3/1;
    }
  }
  @media (max-width: 899px) {
    h2 {
      margin-top: clamp(16px, calc(32vw / 7.68), 32px);
    }
  }
  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }
  h2 {
    max-width: calc(408rem / 16);
  }
  .paragraph {
    max-width: calc(512rem / 16);
    & > *:not(:last-child) {
      margin-bottom: clamp(16px, calc(24vw / 7.68), 24px);
    }
  }
}
