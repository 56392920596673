.BenefitsBackgroundItems {
  h2 {
    max-width: calc(586rem / 16);
    margin: 0 auto clamp(28px, calc(36vw / 7.68), 64px);
    text-align: center;
  }
  > ul {
    display: grid;
    @media (min-width: 550px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1100px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    gap: clamp(12px, calc(20vw / 7.68), 20px);
    list-style-type: none;
    > li {
      padding: clamp(16px, calc(20vw / 7.68), 20px);
      border: 1px solid var(--primary-700);
      background: linear-gradient(-90deg, #1b2138, #0d111f 50%);
      .icon {
        width: clamp(48px, calc(60vw / 7.68), 60px);
        height: clamp(48px, calc(60vw / 7.68), 60px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-700);
        border-radius: 50%;
        img {
          width: clamp(20px, calc(24vw / 7.68), 24px);
          height: clamp(20px, calc(24vw / 7.68), 24px);
          filter: drop-shadow(0px 0px 12px rgba(152, 214, 255, 0.6));
          transform: translate3d(0, 0, 0);
        }
      }
      h3 {
        font-size: clamp(calc(20rem / 16), calc(28vw / 7.68), calc(28rem / 16));
        margin: clamp(12px, calc(16vw / 7.68), 16px) 0 clamp(6px, calc(8vw / 7.68), 12px);
      }
    }
  }
}
