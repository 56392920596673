.Charts {
  display: grid;
  gap: clamp(16px, calc(20vw / 7.68), 32px);

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: clamp(16px, calc(20vw / 7.68), 20px);
    border: 1px solid var(--primary-700);

    > p {
      margin-bottom: 32px;
      font-size: clamp(calc(18rem / 16), calc(24vw / 7.68), calc(24rem / 16));
    }
  }
}

.bars {
  gap: 12px;
  display: grid;

  @media (min-width: 1150px) {
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  > div {
    .bar {
      background-color: var(--primary-700);
      width: 16px;
      height: 182px;
      margin: 0 auto 4px;
      position: relative;

      div {
        position: absolute;
        bottom: 0;

        @media (min-width: 1150px) {
          background: linear-gradient(#4daceb 60%, rgba(255, 255, 255, 0));
          width: 100% !important;
        }

        @media (max-width: 1149px) {
          height: 100% !important;
          background: linear-gradient(-90deg, #4daceb 60%, rgba(255, 255, 255, 0));
        }
      }
    }

    .title {
      font-size: var(--small-font-size);
    }

    @media (max-width: 1149px) {
      display: grid;
      grid-template-columns: 78px 1fr;
      align-items: center;
      gap: 12px;

      .bar {
        height: 16px;
        width: 100%;
        margin: 0;
      }

      .title {
        max-width: 78px;
        overflow: hidden;
        text-overflow: ellipsis;
        order: -1;
      }
    }
  }
}

.linear {
  min-height: clamp(120px, calc(200vw / 7.68), 200px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .bar {
    position: relative;
    height: 16px;

    .progressBar {
      z-index: 2;
      position: absolute;
      height: 100%;
      left: 0;
      background: linear-gradient(-90deg, #4daceb 60%, var(--primary-900));

      &::before {
        content: '';
        position: absolute;
        right: -10px;
        top: -2px;
        width: 20px;
        height: 20px;
        background-color: var(--primary-700);
        border: 4px solid var(--secondary-500);
        box-shadow: 0px 0px 24px 0px rgba(29, 164, 253, 0.6), 0px 0px 9px 0px rgba(152, 214, 255, 0.3);
        transform: scale(0);
        transition: transform 0.5s var(--easing);
      }

      .title {
        position: absolute;
        right: 0px;
        top: -8px;
        transform: translate(50%, -100%);
        opacity: 0;
        transition: opacity 0.5s var(--easing);
      }

      &[data-visible='true'] {
        &::before {
          transform: scale(1);
        }

        .title {
          opacity: 1;
        }
      }
    }
  }

  &[data-angle='false'] {
    padding-bottom: 28px;

    .placeholder {
      position: absolute;
      margin-top: 2px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;

      div {
        width: 12px;
        height: 12px;
        background-color: var(--primary-700);
      }
    }
  }

  &[data-angle='true'] {
    .bar {
      transform: rotate(-15deg);
      transform-origin: left bottom;

      div {
        &::before {
          transform: rotate(15deg) scale(0);
        }

        .title {
          transform: rotate(15deg) translate(50%, -100%);
        }

        &[data-visible='true'] {
          &::before {
            transform: rotate(15deg) scale(1);
          }
        }
      }
    }
  }
}

.circle {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 100%;
  border: 1px solid var(--secondary-500);
  position: relative;
  margin-bottom: 28px;
  overflow: hidden;

  svg {
    overflow: visible;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    display: block;

    circle {
      stroke-width: 50;
      stroke-dasharray: 158;
    }
  }

  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.5s var(--easing);
  }

  &[data-visible='true'] {
    .title {
      opacity: 1;
    }
  }
}
