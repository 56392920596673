.BenefitsItems {
  header {
    text-align: center;
    max-width: calc(689rem / 16);
    margin: 0 auto clamp(28px, calc(36vw / 7.68), 64px);
  }
  .list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: clamp(16px, calc(24vw / 7.68), 24px);
    .item {
      width: 100%;
      @media (min-width: 800px) {
        width: calc(50% - clamp(16px, calc(24vw / 7.68), 24px) * 0.5);
      }
      @media (min-width: 1050px) {
        width: calc(33.3% - clamp(16px, calc(24vw / 7.68), 24px) * 0.67);
      }
      list-style-type: none;
      padding: clamp(32px, calc(32vw / 7.68), 48px) clamp(16px, calc(20vw / 7.68), 20px)
        clamp(28px, calc(32vw / 7.68), 32px);
      background-color: rgba(19, 24, 42, 0.8);
      clip-path: polygon(100% 0, 100% calc(100% - 34px), calc(100% - 34px) 100%, 0 100%, 0 0);
      .icon {
        width: clamp(48px, calc(64vw / 7.68), 80px);
        height: clamp(48px, calc(64vw / 7.68), 80px);
        margin-bottom: clamp(12px, calc(16vw / 7.68), 28px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-700);
        border-radius: 50%;
        img {
          width: clamp(24px, calc(28vw / 7.68), 28px);
          height: clamp(24px, calc(28vw / 7.68), 28px);
          filter: drop-shadow(0px 0px 16px rgba(152, 214, 255, 0.6));
          transform: translate3d(0, 0, 0);
        }
      }
      overflow: hidden;
      position: relative;
      .decoration {
        position: absolute;
        right: -6px;
        top: -6px;
      }
    }
  }
}
